import * as Sentry from "@sentry/react"
import i18next from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import LocizeBackend from "i18next-locize-backend"
import ReactDOM from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.scss'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

Sentry.init({
    dsn: "https://0a659073db48de07c7045977242bef20@o379493.ingest.sentry.io/4506316371132416",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/ckm-billing-.*\.web\.app/],
        }),
        new Sentry.Replay(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

i18next
    .use(LocizeBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            projectId: process.env.REACT_APP_LOCIZE_PORJECT_ID,
            referenceLng: "en",
            lng: "en"
        },
        detection: {
            order: ['querystring'],
            lookupQuerystring: 'locale',
        },
        fallbackLng: "en",
        ns: "latest"
    }, () => { })

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
