import { List } from "@mui/material"
import { Typography } from "cakemail-ui-components-v2"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IPrice } from "../interfaces/IPrice"
import { FeatureCategories } from "../models/FeatureCategories"
import { FeatureDefinitions } from "../models/FeatureDefinitions"
import { EFeatureCategory } from "../types/EFeatureCategory"
import { FullFeatureSet } from "./FullFeatureSet"

export default function Features({ price, }: { price: IPrice }) {
    const [fullFeatureSet, setFullFeatureSet] = useState<any>()
    const { t: translate } = useTranslation()

    useEffect(() => {
        if (price) {
            let tempFeatureSet = new FullFeatureSet(price)
            if (price?.metadata?.category === "premium") {
                tempFeatureSet.dedicated_sending_ip_option = true
                tempFeatureSet.live_chat_support = true
                tempFeatureSet.priority_phone_support = true
                tempFeatureSet.professional_templates = true
            }
            if (price?.metadata?.category === "growth") {
                tempFeatureSet.live_chat_support = true
                tempFeatureSet.professional_templates = true
            }
            setFullFeatureSet(tempFeatureSet.getFeaturesAndLimits())
        }
    }, [price])

    return (
        <>
            <List>
                {FeatureCategories.map((category: EFeatureCategory) => (
                    <div key={category}>
                        <Typography className="category" sx={{ textTransform: "none" }} variant="h3S">
                            {" "}
                            {translate("category-" + category)}
                        </Typography>
                        <div style={{ paddingBottom: "1rem" }}></div>

                        {fullFeatureSet &&
                            Object.keys(FeatureDefinitions).map((feature) => {
                                if (
                                    FeatureDefinitions[feature].category === category &&
                                    (fullFeatureSet[feature] ||
                                        (typeof FeatureDefinitions[feature].value ===
                                            "number" &&
                                            fullFeatureSet[feature] !== 0))
                                ) {
                                    return (
                                        <div key={feature}>
                                            <div style={{ display: "flex", justifyContent: "space-between", }} >
                                                <div>
                                                    <Typography variant="body1L">
                                                        {translate("feature-" + FeatureDefinitions[feature].label)}
                                                    </Typography>
                                                </div>
                                                <Typography variant="body1L">
                                                    {fullFeatureSet[feature] === -1
                                                        ? translate('features-unlimited')
                                                        : typeof fullFeatureSet[feature] === "number"
                                                            ? fullFeatureSet[feature].toLocaleString()
                                                            : ""}
                                                </Typography>
                                            </div>
                                            <div style={{ paddingBottom: "1rem" }}></div>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        <br />
                    </div>
                ))}
            </List>
        </>
    )
}
