import { GenericWrapperContext } from "cakemail-ui-components-v2"
import { useContext, useEffect, useState } from "react"
import { CustomerContext, PricingPageContext } from "../contexts"
import { getPriceById } from "../functions"
import { IContract } from "../interfaces/IContract"
import { IPrice } from "../interfaces/IPrice"
import { IProduct } from "../interfaces/IProduct"
import { PricingPageContextClass } from "../models/PricingPageContextClass"
import { narrowDown } from "./lib/narrowDown"
import { replacePriceIfCurrentSubscriptionIsVerySimilar } from "./lib/replacePriceIfCurrentSubscriptionIsVerySimilar"

/**
 * Provides pricing-related data and functionality to its children components.
 * 
 * @param children - The child components that will have access to the pricing data and functionality.
 */
export default function PricingPageProvider({ children }: { children: JSX.Element[] }) {
    const customerContext = useContext(CustomerContext)
    const genericWrapperContext = useContext(GenericWrapperContext)
    const [categorizedProducts, setCategorizedProducts] = useState<IProduct[]>([])
    const [contactTiers, setContactTiers] = useState<number[]>()
    const [currency, setCurrency] = useState<string>("")
    const [currencies, setCurrencies] = useState<string[]>([])
    const [disableButtons, setDisableButtons] = useState<boolean>(false)
    const [selectedContactTier, setSelectecContactTier] = useState<number>(500)
    const [contractPrice, setContractPrice] = useState<any>(null)
    /**
     * Classifies the prices of the customer's products by category.
     * If the customer has subscriptions, it replaces the prices with similar ones based on the current subscription.
     */
    function classifyPricesByCategory() {
        if (customerContext?.products) {
            const [product]: IProduct[] = customerContext.products
            if (product) {
                const id = product.id
                const metadata = product.metadata
                let p = ['Free', 'Growth', 'Premium'].map((name) => {
                    return {
                        id,
                        name,
                        prices: narrowDown({
                            prices: product.prices,
                            category: name.toLowerCase(),
                        }),
                        metadata,
                    }
                })
                if (customerContext.subscriptions.length > 0) {
                    p = replacePriceIfCurrentSubscriptionIsVerySimilar({ customer: customerContext, products: p })
                }
                setCategorizedProducts(p)
            }
        }
    }

    /**
     * Enumerates the contact tiers based on the categorized products and currency.
     * Sets the contact tiers state variable.
     */
    function enumerateContactTiers() {
        if (categorizedProducts.length && currency) {
            setContactTiers(
                categorizedProducts
                    // Get the prices of the products
                    .map((product: IProduct) => product.prices)
                    // Flatten the array of prices
                    .map((prices: IPrice[]) => prices.map((price: IPrice) => price.metadata.usage_limits.maximum_contacts)
                    )
                    // Flatten the array of contact tiers
                    .flat()
                    // Remove duplicates
                    .filter((price: number, index: number, self: number[]) => index === self.findIndex((p) => p === price)
                    )
                    // Sort the array
                    .sort((a, b) => a - b)
            )
        }
    }

    /**
     * Changes the currency based on the customer's profile.
     * If the customer's profile currency is a string, it sets the currency to that string.
     * If the customer's profile currency is an empty object or undefined, it sets the currency to "cad".
     */
    function changeCurrency() {
        if (window.sessionStorage.getItem("initCurrency")) {
            return setCurrency(window.sessionStorage.getItem("initCurrency")?.toLowerCase() || "cad")
        }
        else if (customerContext) {
            const {
                profile,
                currencyList,
                products,
                subscriptions
            } = customerContext

            const currencyFromCurrentContract = contractPrice?.currency
            const currencyFromCurrentSubscription = subscriptions[0]?.items[0]?.price?.currency
            const billingCurrency = profile?.billingCurrency?.toLowerCase()
            const profileCurrency = profile?.currency
            const currencyFromCurrencyList = currencyList[0]
            const currencyFromProduct = products?.[0]?.prices?.[0]?.currency

            return setCurrency(
                currencyFromCurrentContract ||
                currencyFromCurrentSubscription ||
                billingCurrency ||
                (typeof profileCurrency === "string" && profileCurrency) ||
                currencyFromCurrencyList ||
                currencyFromProduct
            )
        }
        console.error(new Error('No currency can be set'))
    }

    /**
     * Enumerates the currencies based on the customer's products and prices.
     */
    function enumerateCurrencies() {
        if (customerContext && customerContext.profile?.billingCurrency) {
            setCurrencies([customerContext.profile?.billingCurrency.toLowerCase()])
        }
        else if (customerContext && customerContext.products[0]) {
            setCurrencies(customerContext
                .products[0]
                .prices
                .map((price: IPrice) => price.currency)
                .filter((price: string, index: number, self: string[]) => index === self.findIndex((p) => p === price))
            )
        }
    }
    /**
     * Retrieves the contact tier from the current subscription.
     */
    function getContactTierFromCurrentSubscription() {
        if (customerContext && genericWrapperContext?.partnerBrand?.config.contract_redirection_url && customerContext?.profile?.metadata?.contracts && customerContext?.profile?.metadata?.contracts.length > 0) {
            const actualContract = customerContext?.profile?.metadata.contracts?.sort((a: IContract, b: IContract) => b.createTime - a.createTime)[0]
            const priceObject = customerContext?.products[0].prices.find((obj: IPrice) => obj.id === actualContract?.priceId)

            if (priceObject) {
                setContractPrice(priceObject)
                setSelectecContactTier(priceObject?.metadata?.usage_limits.maximum_contacts || 500)
                return
            }

            if (contractPrice === null) {
                setContractPrice({})
                getPriceById({
                    token: customerContext.token,
                    priceId: actualContract.priceId,
                }).then((a: any) => {
                    setContractPrice({ ...a.data, legacy: true })
                }).catch((e: any) => {
                    setContractPrice(null)
                    console.error(e)
                })
                return
            }
            return
        }
        if (customerContext && customerContext.subscriptions.length > 0) {
            const subscription = customerContext.subscriptions[0]
            const price = subscription.items[0].price
            if (price.metadata?.category === "free") return
            if (price.metadata?.usage_limits?.maximum_contacts) {
                setSelectecContactTier(
                    Number(price.metadata?.usage_limits?.maximum_contacts)
                )
            }
            return
        }
    }

    /**
     * Hides the loading state for categorized products.
     */
    function hideCategorizedProductsLoading() {
        if (customerContext) {
            return customerContext.setUpdating(categorizedProducts.length === 0)
        }
    }

    useEffect(enumerateCurrencies, [customerContext])
    useEffect(classifyPricesByCategory, [customerContext])
    useEffect(enumerateContactTiers, [categorizedProducts, currency])
    useEffect(changeCurrency, [customerContext, contractPrice])
    useEffect(getContactTierFromCurrentSubscription, [customerContext, genericWrapperContext?.partnerBrand, contractPrice])
    useEffect(hideCategorizedProductsLoading, [categorizedProducts, customerContext])

    return <>
        <PricingPageContext.Provider value={new PricingPageContextClass({
            products: categorizedProducts,
            contactTiers,
            currencies,
            currency,
            disabledButtons: disableButtons,
            selectedContactTier,
            contractPrice,
            setProducts: setCategorizedProducts,
            setCurrency,
            setCurrencies,
            setDisableButtons,
            setSelectecContactTier,
            setContractPrice,
        })}>
            {customerContext?.products && customerContext.products.length > 0 && children}
        </PricingPageContext.Provider>
    </>
}