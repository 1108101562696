import { Container } from "@mui/material"
import { GenericWrapper } from "cakemail-ui-components-v2"
import { useSearchParams } from "react-router-dom"
import "./App.css"
import CustomerContextProvider from "./components/CustomerContextProvider"
import ErrorMessage from "./components/ErrorMessage"
import Loading from "./components/Loading"
import PricingPage from "./components/PricingPage"
import { CustomerContext } from "./contexts"
import { useEffect, useState } from "react"

export default function App() {
    const [searchParams] = useSearchParams()
    const [brand, setBrand] = useState<string>("")

    useEffect(() => {
        setBrand(searchParams.get("brandId") || brand || process.env.REACT_APP_DEFAULT_BRAND_ID || "")
    }, [searchParams, brand])

    return (
        <>
            {brand && (
                <GenericWrapper
                    brandThemeContext="billing_portal"
                    brandId={brand}
                    brandHost={process.env.REACT_APP_BRAND_HOST}
                >
                    <CustomerContextProvider>
                        <CustomerContext.Consumer>
                            {(customerContext) => (
                                <>
                                    <Loading />
                                    {customerContext?.brand.partnerUrl && (
                                        <>
                                            <ErrorMessage />
                                            <Container>
                                                <PricingPage />
                                            </Container>
                                        </>
                                    )}
                                </>
                            )}
                        </CustomerContext.Consumer>
                    </CustomerContextProvider>
                </GenericWrapper>
            )}
        </>
    )
}
