import { IPrice } from "../../interfaces/IPrice"

/**
 * Filters an array of prices based on the specified category.
 * @param prices - The array of prices to filter.
 * @param category - The category to filter by.
 * @returns The filtered array of prices.
 */
export function narrowDown({ prices, category }: { prices: IPrice[]; category: string }): IPrice[] {
    return prices
        .filter((price: IPrice) => price.metadata.category === category)
}
