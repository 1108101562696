import { CircularProgress } from "@mui/material"
import { useContext } from "react"
import { CustomerContext } from "../contexts"

/**
 * Renders a loading indicator based on the updating state from the customer context.
 * @returns The JSX element representing the loading indicator.
 */
export default function Loading(): JSX.Element {
    const customerContext = useContext(CustomerContext)
    return <>
        {customerContext?.updating &&
            <div className="loading">
                <CircularProgress size="5em" color="secondary" />
            </div>
        }
    </>
}