/**
 * Checks if two objects are the same
 * @param left - The first object to compare.
 * @param right - The second object to compare.
 * @returns True if the limits are the same, false otherwise.
 */

export function limitsAreTheSame(left: any, right: any): boolean {
    return JSON.stringify(left) === JSON.stringify(right)
}
