import { useContext, useEffect } from "react"
import { CustomerContext, PricingPageContext } from "../contexts"
import { Messages } from "../models/Messages"
import LegacyPriceCard from "./LegacyPriceCard"
import StandardPriceCards from "./StandardPriceCards"
import { isSubscribedToLegacyPlan } from "./lib/isSubscribedToLegacyPlan"

export default function PricingGrid() {
    const pricingPageContext = useContext(PricingPageContext)
    const customerContext = useContext(CustomerContext)

    useEffect(() => {
        window.onresize = Messages.sendHeightToParent
    }, [])

    useEffect(() => {
        Messages.sendHeightToParent()
    }, [pricingPageContext])

    return <>
        {
            isSubscribedToLegacyPlan(customerContext, pricingPageContext)  ? (
                <div className="with-legacy-card-container">
                    <LegacyPriceCard />
                    <StandardPriceCards />
                </div>
            ) : (
                <div className="default-card-container">
                    <StandardPriceCards />
                </div>
            )
        }

    </>
}