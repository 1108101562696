import { IProduct } from "../../interfaces/IProduct"
import { CustomerContextType } from "../../types/CustomerContextType"
import { pricesAreSimilarEnough } from "./pricesAreSimilarEnough"

/**
 * Replaces the price of products if the current subscription is very similar.
 * @param {Object} options - The options object.
 * @param {CustomerContextType} options.customer - The customer context.
 * @param {IProduct[]} options.products - The list of products.
 * @returns {IProduct[]} - The updated list of products.
 */
export function replacePriceIfCurrentSubscriptionIsVerySimilar({ customer, products }: { customer: CustomerContextType; products: IProduct[] }): IProduct[] {
    if (customer && products) {
        const subscription = customer.subscriptions[0]
        const subscribedPrice = subscription?.items[0]?.price
        // if the customer is not subscribed to anything, return the products as is
        if (!subscribedPrice) {
            return products
        }

        if (customer.authenticatedMode) {
            for (const product of products) {
                product.prices.forEach((price, index) => {
                    if (pricesAreSimilarEnough(subscribedPrice, price)) {
                        // unflag as legacy, replace the price in the grid
                        subscription.legacy = false
                        product.prices[index] = subscribedPrice
                    }
                })
            }
        }
        return products
    }
    return []
}
