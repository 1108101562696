import Header from "./Header"
import PlanGridContainer from "./PlanGridContainer"
import PricingPageProvider from "./PricingCardsProvider"
import PricingGrid from "./PricingGrid"


export default function PricingPage() {
    return <>
        <PricingPageProvider>
            <Header />
            <PlanGridContainer>
                <PricingGrid />
            </PlanGridContainer>
        </PricingPageProvider>
    </>
}
