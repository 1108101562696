import { IPrice } from "../../interfaces/IPrice"
import { IProduct } from "../../interfaces/IProduct"

/**
 * Retrieves the price tier for a given product, number of contacts, and currency.
 * @param product - The product to retrieve the price tier for.
 * @param contactTier - The number of contacts.
 * @param currency - The currency.
 * @returns The price tier for the given product, contacts, and currency.
 */
export function getPriceByContactTier(product: IProduct, contactTier: number, currency: string | undefined): IPrice | undefined {
    // for free products, get the first currency match regardless of contact tier
    if (product.prices[0]?.metadata?.category === "free") {
        return product.prices.find((price: IPrice) => price.currency === currency)
    }

    return product.prices.find(
        (price: IPrice) => Number(price.metadata.usage_limits.maximum_contacts) === contactTier &&
            price.currency === currency
    )
}