import { IPrice } from "../../interfaces/IPrice"
import { limitsAreTheSame } from "./limitsAreTheSame"

/**
 * Checks if two prices are similar enough.
 * @param leftPrice - The left price to compare.
 * @param rightPrice - The right price to compare.
 * @returns A boolean indicating whether the prices are similar enough.
 */
export function pricesAreSimilarEnough(leftPrice: IPrice, rightPrice: IPrice): boolean {
    return (
        leftPrice?.currency === rightPrice?.currency &&
        leftPrice?.metadata?.uuid === rightPrice?.metadata?.uuid &&
        limitsAreTheSame(leftPrice?.metadata?.usage_limits, rightPrice?.metadata?.usage_limits)
    )
}
