export function getHttpsCallableError(error: any): { code?: string, details?: any, name?: string } {
    try {
        // some properties may not exist, so defining them in the return will make sure they are kept
        const { code, details, name } = JSON.parse(JSON.stringify(error))
        return { code, details, name }
    } catch (e) {
        console.error("failed to parse error")
        return {
            code: undefined,
            details: undefined,
            name: undefined
        }
    }
}