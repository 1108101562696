import { Dispatch, SetStateAction } from "react"
import { IProduct } from "../interfaces/IProduct"
import { PricingCardsContextType } from "../interfaces/PricingCardsContext"


export class PricingPageContextClass implements PricingCardsContextType {
    static bodyHeight: number = 0;

    products: IProduct[]
    contactTiers?: number[]
    selectedContactTier: number
    disabledButtons: boolean
    currencies?: string[]
    currency: string
    contractPrice: any

    setProducts: Dispatch<SetStateAction<IProduct[]>>
    setSelectecContactTier: Dispatch<SetStateAction<number>>
    setDisableButtons: Dispatch<SetStateAction<boolean>>
    setCurrencies: Dispatch<SetStateAction<string[]>>
    setCurrency: Dispatch<SetStateAction<string>>
    setContractPrice: Dispatch<any>

    constructor(init: PricingCardsContextType) {
        this.products = init.products
        this.contactTiers = init.contactTiers
        this.selectedContactTier = init.selectedContactTier
        this.disabledButtons = init.disabledButtons
        this.currencies = init.currencies
        this.currency = init.currency
        this.contractPrice = init.contractPrice

        this.setProducts = init.setProducts
        this.setSelectecContactTier = init.setSelectecContactTier
        this.setDisableButtons = init.setDisableButtons
        this.setCurrencies = init.setCurrencies
        this.setCurrency = init.setCurrency
        this.setContractPrice = init.setContractPrice
    }

    toggleButtons() {
        this.setDisableButtons(!this.disabledButtons)
    }
}
