import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Paper from "@mui/material/Paper"
import { Button } from "cakemail-ui-components-v2"
import { TSimpleDialog } from "./types"

export function SimpleDialog({
    open,
    handleClose,
    title,
    content,
    primaryBtn,
    secondaryBtn
}: TSimpleDialog) {

    return <Dialog
        PaperProps={{
            style: {
                // Position the dialog in the middle using em units
                borderRadius: '24px',
                top: "-30%",
            },
        }}
        onClose={handleClose}
        open={open}
    >
        <Paper style={{ padding: '32px' }} >
            <div>
                {title &&
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                }

                {content &&
                    <DialogContent style={{ padding: '24px' }}  >
                        <DialogContentText id="alert-dialog-slide-description">
                            {content}
                        </DialogContentText>
                    </DialogContent>
                }

                <DialogActions>
                    {secondaryBtn &&
                        <Button
                            color="cloud"
                            disabled={secondaryBtn.disabled || false}
                            onClick={secondaryBtn.onClick || handleClose}
                        >
                            {secondaryBtn.text}
                        </Button>
                    }

                    {primaryBtn &&
                        <Button
                            color="secondary"
                            disabled={primaryBtn.disabled || false}
                            onClick={primaryBtn.onClick}
                        >
                            {primaryBtn.text}
                        </Button>
                    }

                </DialogActions>
            </div>
        </Paper>
    </Dialog>
}