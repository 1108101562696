import { PricingPageContextClass } from "./PricingPageContextClass"

export class Messages {
    /**
     * Requests a token refresh by sending a message to the parent window.
     */
    static requestTokenRefresh() {
        window.parent.postMessage({ type: 'tokenRefresh' }, '*')
    }

    /**
    * Requests navigation to the specified URL.
    * @param url - The URL to navigate to.
    */
    static requestNavigateTo(url: string, brandUrl: string) {
        window.parent.postMessage({ type: 'navigate', url: url }, 'https://' + brandUrl)
    }

    /**
     * Sends the height of the document body to the parent window.
     */
    static sendHeightToParent() {
        const height = document.documentElement.getBoundingClientRect().height
        if (PricingPageContextClass.bodyHeight !== height) {
            PricingPageContextClass.bodyHeight = height
            window.parent.postMessage({ type: 'height', height: height }, '*')
        }
    }
    /**
     * Sends the parent a request to create a Zendesk Ticket.
     * */

    static sendZendeskTicketRequest(type: "support" | "downgrade" = "support", message = "") {
        window.parent.postMessage({ type: type, message: message }, '*')
    }
}